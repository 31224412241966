import React from 'react'
import { Toast, useToastState } from '@tamagui/toast'
import { XStack, YStack } from 'tamagui'
import { X, Check } from '@tamagui/lucide-icons'

export const NativeToast = () => {
  const currentToast = useToastState()

  if (!currentToast || currentToast.isHandledNatively) {
    return null
  }

  const { preset = 'success' } = currentToast.customData as { preset: 'success' | 'error' }

  return (
    <Toast
      key={currentToast.id}
      duration={currentToast.duration}
      viewportName={currentToast.viewportName}
      enterStyle={{ opacity: 0, scale: 0.9, y: -25 }}
      exitStyle={{ opacity: 0, scale: 1, y: -20 }}
      y={0}
      opacity={1}
      scale={1}
      animation="normal"
    >
      <XStack
        py="$2"
        gap="$1.5"
        px="$2"
        pr="$3"
        ai="center"
        bg="$secondary10"
        br="$4"
        boc="$primary10"
        bw={1}
      >
        {preset === 'success' && <Check size="$2" color="$green6" />}
        {preset === 'error' && <X size="$2" color="$red6" />}
        <YStack>
          <Toast.Title size="$4" color="$primary1" fow="600">
            {currentToast.title}
          </Toast.Title>
          {!!currentToast.message && (
            <Toast.Description size="$3" color="$primary1" fow="400">
              {currentToast.message}
            </Toast.Description>
          )}
        </YStack>
      </XStack>
    </Toast>
  )
}
