import {
  CustomToast,
  TamaguiProvider,
  TamaguiProviderProps,
  ToastProvider,
} from '@mythical/ui'
import { config } from '@mythical/ui-config'
import appConfig from 'app/config'
import { RootStore, RootStoreProvider, setupRootStore } from 'app/store'
import React, { useEffect, useState } from 'react'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'

import { NavigationProvider } from './navigation'
import { SafeArea } from './safe-area'
import { ToastViewport } from './ToastViewport'

interface ProviderProps extends Omit<TamaguiProviderProps, 'config'> {
  queryState?: any
  waitForRootStore?: boolean
}

export function Provider({
  children,
  queryState,
  waitForRootStore = true,
  ...rest
}: ProviderProps) {
  const [rootStore, setRootStore] = useState<RootStore | null>(
    waitForRootStore ? null : ({} as RootStore)
  )
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 1000 * 60,
          },
        },
      })
  )

  useEffect(() => {
    ;(async () => {
      setupRootStore(appConfig).then(setRootStore)
    })()
  }, [])

  if (waitForRootStore && !rootStore) return null

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={queryState}>
        <NavigationProvider>
          <RootStoreProvider value={rootStore as RootStore}>
            <SafeArea>
              <TamaguiProvider
                config={config}
                disableInjectCSS
                defaultTheme="light"
                {...rest}
              >
                <ToastProvider
                  swipeDirection="horizontal"
                  duration={6000}
                  native={[

                  ]}
                >
                  {children}
                  <CustomToast />
                  <ToastViewport />
                </ToastProvider>
              </TamaguiProvider>
            </SafeArea>
          </RootStoreProvider>
        </NavigationProvider>
      </Hydrate>
    </QueryClientProvider>
  )
}
