import { Provider } from 'app/provider'
import React, { useEffect, useMemo } from 'react'
import type { SolitoAppProps } from 'solito'
import ProgressBar from '@badrap/bar-of-progress'
import Script from 'next/script'
import 'raf/polyfill'

import { NextThemeProvider, useRootTheme } from '@tamagui/next-theme'

import '@tamagui/core/reset.css'
import '../styles/index.css'
import 'react-medium-image-zoom/dist/styles.css'
import 'swiper/css'

import GlobalMeta from '../src/Meta'
import { useRouter } from 'next/router'
import { useScrollRestoration } from 'app/hooks/use-scroll-restoration'
import * as fbq from 'app/lib/fbpixel'

if (process.env.NODE_ENV === 'production') {
  // require('../public/tamagui.css')
}

const progress = new ProgressBar({
  size: 2,
  color: '#CFAB5E',
  className: 'bar-of-progress',
  delay: 150,
})

function MyApp({
  Component,
  pageProps,
}: SolitoAppProps<{ dehydratedState: any }>) {
  const [theme, setTheme] = useRootTheme()
  const router = useRouter()
  const contents = useMemo(() => {
    // @ts-ignore
    return <Component {...pageProps} />
  }, [Component, pageProps])

  useScrollRestoration()

  useEffect(() => {
    const handleRouteChange = function () {
      progress.finish()
      fbq.pageview()
    }

    router.events.on('routeChangeStart', progress.start)
    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('routeChangeError', progress.finish)
    fbq.pageview()

    return () => {
      router.events.off('routeChangeStart', progress.start)
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('routeChangeError', progress.finish)
    }
  }, [router])

  return (
    <>
      <GlobalMeta />
      <Script
        type="text/javascript"
        src="https://cookie-cdn.cookiepro.com/consent/d51fb069-37e7-4afc-8ff0-e832e6980497/OtAutoBlock.js"
      />
      <Script
        src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script="d51fb069-37e7-4afc-8ff0-e832e6980497"
      />
      <Script id="cookie-pro" type="text/javascript">
        {`function OptanonWrapper() { }`}
      </Script>

      <Script
        id="accessibe"
        strategy="afterInteractive"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `(function(){
          var s    = document.createElement('script');
          var h    = document.querySelector('head') || document.body;
          s.src    = 'https://acsbapp.com/apps/app/dist/js/app.js';
          s.async  = true;
          s.onload = function(){
              acsbJS.init({
                  statementLink    : '',
                  footerHtml       : '',
                  hideMobile       : false,
                  hideTrigger      : false,
                  disableBgProcess : false,
                  language         : 'en',
                  position         : 'right',
                  leadColor        : '#2a3314',
                  triggerColor     : '#cfab5a',
                  triggerRadius    : '50%',
                  triggerPositionX : 'right',
                  triggerPositionY : 'bottom',
                  triggerIcon      : 'people',
                  triggerSize      : 'bottom',
                  triggerOffsetX   : 20,
                  triggerOffsetY   : 20,
                  mobile           : {
                      triggerSize      : 'small',
                      triggerPositionX : 'right',
                      triggerPositionY : 'bottom',
                      triggerOffsetX   : 10,
                      triggerOffsetY   : 10,
                      triggerRadius    : '20'
                  }
              });
          };
          h.appendChild(s);
        })();`,
        }}
      />

      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />

      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NH7THDG');
      `}
      </Script>

      <NextThemeProvider onChangeTheme={setTheme}>
        <Provider
          disableRootThemeClass
          disableInjectCSS
          defaultTheme={theme}
          queryState={pageProps.dehydratedState}
          waitForRootStore={false}
        >
          {contents}
        </Provider>
      </NextThemeProvider>
    </>
  )
}

MyApp.displayName = 'MythicalApp'

export default MyApp
