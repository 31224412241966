import { color } from './tokens'

const light = {
  color: color.secondary10,
  background: color.primary10,
  borderColor: color.primary10,
  borderColorHover: color.primary10,
  borderColorFocus: color.primary10,
  borderColorPress: color.primary10,
  shadowColor: color.secondary10,
  shadowColorHover: color.secondary10,
  backgroundHover: color.primary9,
  backgroundFocus: color.primary8,
  backgroundPress: color.primary7,
}

const dark = {
  color: color.secondary10,
  background: color.primary10,
  borderColor: color.primary10,
  borderColorHover: color.primary10,
  borderColorFocus: color.primary10,
  borderColorPress: color.primary10,
  shadowColor: color.secondary10,
  shadowColorHover: color.secondary10,
  backgroundHover: color.primary9,
  backgroundFocus: color.primary8,
  backgroundPress: color.primary7,
}

type BaseTheme = typeof light

const secondary = {
  color: color.primary10,
  background: color.secondary10,
  borderColor: color.secondary10,
  borderColorHover: color.secondary7,
  borderColorFocus: color.secondary7,
  borderColorPress: color.secondary7,
  shadowColor: color.secondary10,
  shadowColorHover: color.secondary10,
  backgroundHover: color.secondary8,
  backgroundFocus: color.secondary7,
  backgroundPress: color.secondary7,
}

const tertiary = {
  color: color.primary6,
  background: color.secondary8,
  borderColor: color.secondary8,
  borderColorHover: color.secondary7,
  borderColorFocus: color.secondary7,
  borderColorPress: color.secondary7,
  shadowColor: color.secondary10,
  shadowColorHover: color.secondary10,
  backgroundHover: color.secondary7,
  backgroundFocus: color.secondary7,
  backgroundPress: color.secondary7,
}

const outline = {
  color: color.primary10,
  background: color.secondary10,
  borderColor: color.primary10,
  borderColorHover: color.primary10,
  borderColorFocus: color.primary10,
  borderColorPress: color.primary10,
  shadowColor: color.secondary10,
  shadowColorHover: color.secondary10,
  backgroundHover: color.secondary9,
  backgroundFocus: color.secondary9,
  backgroundPress: color.secondary9,
}

const outlineSecondary9 = {
  color: color.primary7,
  background: color.secondary9,
  borderColor: color.primary6,
  borderColorHover: color.primary7,
  borderColorFocus: color.primary7,
  borderColorPress: color.primary7,
  shadowColor: color.secondary10,
  shadowColorHover: color.secondary10,
  backgroundHover: color.secondary8,
  backgroundFocus: color.secondary8,
  backgroundPress: color.secondary8,
}

const secondary7 = {
  color: color.secondary1,
  background: color.secondary7,
  borderColor: color.secondary7,
  borderColorHover: color.secondary7,
  borderColorFocus: color.secondary7,
  borderColorPress: color.secondary7,
  shadowColor: color.secondary10,
  shadowColorHover: color.secondary10,
  backgroundHover: color.secondary6,
  backgroundFocus: color.secondary6,
  backgroundPress: color.secondary6,
}

const secondary8 = {
  color: color.secondary1,
  background: color.secondary8,
  borderColor: color.secondary8,
  borderColorHover: color.secondary8,
  borderColorFocus: color.secondary8,
  borderColorPress: color.secondary8,
  shadowColor: color.secondary10,
  shadowColorHover: color.secondary10,
  backgroundHover: color.secondary7,
  backgroundFocus: color.secondary7,
  backgroundPress: color.secondary7,
}

const secondary8Alt = {
  color: color.secondary1,
  background: color.secondary8,
  borderColor: color.secondary8,
  borderColorHover: color.secondary8,
  borderColorFocus: color.secondary8,
  borderColorPress: color.secondary8,
  shadowColor: color.secondary10,
  shadowColorHover: color.secondary10,
  backgroundHover: color.secondary7,
  backgroundFocus: color.secondary7,
  backgroundPress: color.secondary7,
}

const secondary8Outline = {
  color: color.secondary1,
  background: color.secondary8,
  borderColor: color.primary8,
  borderColorHover: color.primary9,
  borderColorFocus: color.primary9,
  borderColorPress: color.primary9,
  shadowColor: color.secondary10,
  shadowColorHover: color.secondary10,
  backgroundHover: color.secondary9,
  backgroundFocus: color.secondary9,
  backgroundPress: color.secondary9,
}

const secondary9 = {
  color: color.secondary5,
  background: color.secondary9,
  borderColor: color.secondary9,
  borderColorHover: color.secondary10,
  borderColorFocus: color.secondary10,
  borderColorPress: color.secondary10,
  shadowColor: color.secondary10,
  shadowColorHover: color.secondary10,
  backgroundHover: color.secondary10,
  backgroundFocus: color.secondary10,
  backgroundPress: color.secondary10,
}

const secondary9Alt = {
  color: color.primary6,
  background: color.secondary9,
  borderColor: color.secondary9,
  borderColorHover: color.secondary8,
  borderColorFocus: color.secondary8,
  borderColorPress: color.secondary8,
  shadowColor: color.secondary8,
  shadowColorHover: color.secondary8,
  backgroundHover: color.secondary8,
  backgroundFocus: color.secondary8,
  backgroundPress: color.secondary8,
}

const secondary10 = {
  color: color.secondary5,
  background: color.secondary10,
  borderColor: color.secondary10,
  borderColorHover: color.secondary9,
  borderColorFocus: color.secondary9,
  borderColorPress: color.secondary9,
  shadowColor: color.secondary10,
  shadowColorHover: color.secondary10,
  backgroundHover: color.secondary9,
  backgroundFocus: color.secondary9,
  backgroundPress: color.secondary9,
}

const transparent = {
  color: color.primary1,
  background: color.transparent,
  borderColor: color.transparent,
  borderColorHover: color.transparent,
  borderColorFocus: color.transparent,
  borderColorPress: color.transparent,
  shadowColor: color.transparent,
  shadowColorHover: color.transparent,
  backgroundHover: color.transparent,
  backgroundFocus: color.transparent,
  backgroundPress: color.transparent,
}

const white = {
  color: color.secondary10,
  background: color.white,
  borderColor: color.white,
  borderColorHover: color.white,
  borderColorFocus: color.white,
  borderColorPress: color.white,
  shadowColor: color.black,
  shadowColorHover: color.black,
  backgroundHover: color.primary1,
  backgroundFocus: color.primary1,
  backgroundPress: color.primary1,
}

const allThemes = {
  light,
  dark,
  tertiary: tertiary,
  secondary: secondary,
  outline: outline,
  outlineSecondary9: outlineSecondary9,
  secondary7: secondary7,
  secondary8: secondary8,
  secondary8Alt: secondary8Alt,
  secondary8Outline: secondary8Outline,
  secondary9: secondary9,
  secondary9Alt: secondary9Alt,
  secondary10: secondary10,
  transparent: transparent,
  white: white,
}

type ThemeName = keyof typeof allThemes

type Themes = {
  [key in ThemeName]: BaseTheme
}

export const themes: Themes = allThemes
